﻿export const MAIN_COLOUR = '#142636';
export const SECONDARY_COLOUR = "#4A74A5";
export const TERTIARY_COLOUR = "#3FB39A";
export const SCITECH = '#0076FF';
export const ART = '#9013FE';
export const ACTIVISM = '#50E3C2';
export const SCITECH_ART = '#4845FF';
export const ART_ACTIVISM = '#707BE0';
export const ACTIVISM_SCITECH = '#28ADE1';
export const WHITE = '#FFFFFF';
export const GREY_33 = '#333333';
export const GREY_78 = '#787878';
export const GREY_7E = '#7E7E7E';
export const GREY_CC = '#CCCCCC';
export const GREY_D8 = '#D8D8D8';
export const BLACK = '#000000';
export const ERROR_COLOUR = '#FF3A3A';

export const LAT_BOUNDS = { MAX: 85, MIN: -85 };
export const LNG_BOUNDS = { MAX: 180, MIN: -180 };

export const mainTags = [
    { value: 'act', label: 'Activism' },
    { value: 'aas', label: 'Adaption at sea' },
    { value: 'aest', label: 'Aesthetics' },
    { value: 'ant', label: 'Anthropocene' },
    { value: 'aqc', label: 'Aquaculture' },
    { value: 'atmo', label: 'Atmospheric phenomena' },
    { value: 'cap', label: 'Capitalism' },
    { value: 'clic', label: 'Climate change' },
    { value: 'cac', label: 'Costal areas & cities' },
    { value: 'com', label: 'Commons' },
    { value: 'edu', label: 'Education' },
    { value: 'epi', label: 'Epistemologies' },
    { value: 'food', label: 'Food' },
    { value: 'gen', label: 'Gender' },
    { value: 'gPol', label: 'Geopolitics' },
    { value: 'gov', label: 'Governance' },
    { value: 'hist', label: 'History' },
    { value: 'humn', label: 'Humanity' },
    { value: 'haSe', label: 'Humans at sea' },
    { value: 'idty', label: 'Identity' },
    { value: 'ind', label: 'Industry' },
    { value: 'infs', label: 'Infrastructure' },
    { value: 'islW', label: 'Island worlds' },
    { value: 'jus', label: 'Justice' },
    { value: 'lab', label: 'Labor' },
    { value: 'mEco', label: 'Marine Ecosystems' },
    { value: 'mGeo', label: 'Marine geology' },
    { value: 'mWil', label: 'Marine wildlife' },
    { value: 'mobi', label: 'Mobilities' },
    { value: 'oCir', label: 'Ocean circulation' },
    { value: 'oHea', label: 'Ocean health' },
    { value: 'oPol', label: 'Ocean pollution' },
    { value: 'rTol', label: 'Research tools' },
    { value: 'rExt', label: 'Resource extraction' },
    { value: 'rure', label: 'Rules + regulation' },
    { value: 'sPrp', label: 'Seawater properties' },
    { value: 'soci', label: 'Social' },
    { value: 'sFut', label: 'Speculative futures' },
    { value: 'uTec', label: 'Underwater technology' },
    { value: 'vwc', label: 'Vertical water column' },
    { value: 'vuln', label: 'Vulnerability' },
    { value: 'wast', label: 'Waste' }
];

export const itemTypes = [
    { value: 'ap', label: 'Academic Publication' },
    { value: 'ar', label: 'Article' },
    { value: 'nw', label: 'News' },
    { value: 'pp', label: 'Policy Paper' },
    { value: 'rp', label: 'Report' },
    { value: 'bk', label: 'Book' },
    { value: 'ey', label: 'Essay' },
    { value: 'ht', label: 'Historical Text' },
    { value: 'ep', label: 'Event Press' },
    { value: 'tk', label: 'Toolkit' },
    { value: 'mv', label: 'Movie' },
    { value: 'dc', label: 'Documentary' },
    { value: 'rs', label: 'Research' },
    { value: 'iv', label: 'Interview' },
    { value: 'nj', label: 'News/Journalism' },
    { value: 'er', label: 'Event Recording' },
    { value: 'lr', label: 'Lecture Recording' },
    { value: 'if', label: 'Informational Video' },
    { value: 'tr', label: 'Trailer' },
    { value: 'ad', label: 'Artwork Documentation' },
    { value: 'rf', label: 'Raw Footage' },
    { value: 'vd', label: 'Video' },
    { value: 'ph', label: 'Photograph' },
    { value: 'da', label: 'Digital Art' },
    { value: 'gr', label: 'Graphics' },
    { value: 'mp', label: 'Map' },
    { value: 'fs', label: 'Film Still' },
    { value: 'sc', label: 'Sculpture' },
    { value: 'pt', label: 'Painting' },
    { value: 'il', label: 'Illustration' },
    { value: 'dr', label: 'Drawing' },
    { value: 'fr', label: 'Field Recording' },
    { value: 'sa', label: 'Sound Art' },
    { value: 'ms', label: 'Music' },
    { value: 'pd', label: 'Podcast' },
    { value: 'lv', label: 'Lecture Video' },
    { value: 'rd', label: 'Radio' },
    { value: 'py', label: 'Poetry' },
    { value: 'ot', label: 'Other' },
];

//Constants from current Ocean Archive GitHub
//https://github.com/AcrossTheCloud/TBA21-client/blob/master/src/components/metadata/ItemEditor.tsx
export const oceans = [
    { value: 'ATLANTIC', label: 'Atlantic Ocean' },
    { value: 'ARCTIC', label: 'Arctic Ocean' },
    { value: 'INDIAN', label: 'Indian Ocean' },
    { value: 'PACIFIC', label: 'Pacific Ocean' },
    { value: 'SOUTHERN', label: 'Southern Ocean' }
];

export const countries = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AX', label: '\u00c5land Islands' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia, Plurinational State of' },
    { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CD', label: 'Congo, the Democratic Republic of the' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CI', label: 'C\u00f4te d\'Ivoire' },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Cura\u00e7ao' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FK', label: 'Falkland Islands (Malvinas)' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'TF', label: 'French Southern Territories' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Greece' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HM', label: 'Heard Island and McDonald Islands' },
    { value: 'VA', label: 'Holy See (Vatican City State)' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran, Islamic Republic of' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KP', label: 'Korea, Democratic People\'s Republic of' },
    { value: 'KR', label: 'Korea, Republic of' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: 'Lao People\'s Democratic Republic' },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MO', label: 'Macao' },
    { value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia, Federated States of' },
    { value: 'MD', label: 'Moldova, Republic of' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palestine, State of' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RE', label: 'R\u00e9union' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russian Federation' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'BL', label: 'Saint Barth\u00e9lemy' },
    { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin (French part)' },
    { value: 'PM', label: 'Saint Pierre and Miquelon' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SX', label: 'Sint Maarten (Dutch part)' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syrian Arab Republic' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania, United Republic of' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
    { value: 'VN', label: 'Viet Nam' },
    { value: 'VG', label: 'Virgin Islands, British' },
    { value: 'VI', label: 'Virgin Islands, U.S.' },
    { value: 'WF', label: 'Wallis and Futuna' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' }
];

export const regions = [
    ...oceans,
    ...countries
];

export const languages = [
    { value: 'AR', label: 'Arabic' },
    { value: 'BG', label: 'Bulgarian' },
    { value: 'CA', label: 'Catalan' },
    { value: 'ZH', label: 'Hans Chinese, Han' },
    { value: 'CS', label: 'Czech' },
    { value: 'DA', label: 'Danish' },
    { value: 'DE', label: 'German' },
    { value: 'EL', label: 'Modern Greek' },
    { value: 'EN', label: 'English' },
    { value: 'ES', label: 'Spanish' },
    { value: 'FI', label: 'Finnish' },
    { value: 'FR', label: 'French' },
    { value: 'HE', label: 'Hebrew' },
    { value: 'HU', label: 'Hungarian' },
    { value: 'IS', label: 'Icelandic' },
    { value: 'IT', label: 'Italian' },
    { value: 'JA', label: 'Japanese' },
    { value: 'KO', label: 'Korean' },
    { value: 'NL', label: 'Dutch' },
    { value: 'NO', label: 'Norwegian' },
    { value: 'PL', label: 'Polish' },
    { value: 'PT', label: 'Portuguese' },
    { value: 'RM', label: 'Romansh' },
    { value: 'RO', label: 'Romanian' },
    { value: 'RU', label: 'Russian' },
    { value: 'HR', label: 'Croatian' },
    { value: 'SK', label: 'Slovak' },
    { value: 'SQ', label: 'Albanian' },
    { value: 'SV', label: 'Swedish' },
    { value: 'TH', label: 'Thai' },
    { value: 'TR', label: 'Turkish' },
    { value: 'UR', label: 'Urdu' },
    { value: 'ID', label: 'Indonesian' },
    { value: 'UK', label: 'Ukrainian' },
    { value: 'BE', label: 'Belarusian' },
    { value: 'SL', label: 'Slovenian' },
    { value: 'ET', label: 'Estonian' },
    { value: 'LV', label: 'Latvian' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'TG', label: 'Tajik' },
    { value: 'FA', label: 'Persian' },
    { value: 'VI', label: 'Vietnamese' },
    { value: 'HY', label: 'Armenian' },
    { value: 'AZ', label: 'Azerbaijani' },
    { value: 'EU', label: 'Basque' },
    { value: 'HSB', label: 'Upper Sorbian' },
    { value: 'MK', label: 'Macedonian' },
    { value: 'TN', label: 'Tswana' },
    { value: 'XH', label: 'Xhosa' },
    { value: 'ZU', label: 'Zulu' },
    { value: 'AF', label: 'Afrikaans' },
    { value: 'KA', label: 'Georgian' },
    { value: 'FO', label: 'Faroese' },
    { value: 'HI', label: 'Hindi' },
    { value: 'MT', label: 'Maltese' },
    { value: 'SE', label: 'Northern Sami' },
    { value: 'GA', label: 'Irish' },
    { value: 'MS', label: 'Malay' },
    { value: 'KK', label: 'Kazakh' },
    { value: 'KY', label: 'irghiz' },
    { value: 'SW', label: 'Swahili' },
    { value: 'TK', label: 'Turkmen' },
    { value: 'UZ', label: 'Uzbek' },
    { value: 'TT', label: 'Tatar' },
    { value: 'BN', label: 'Bengali' },
    { value: 'PA', label: 'Panjabi' },
    { value: 'GU', label: 'Gujarati' },
    { value: 'OR', label: 'Oriya' },
    { value: 'TA', label: 'Tamil' },
    { value: 'TE', label: 'Telugu' },
    { value: 'KN', label: 'Kannada' },
    { value: 'ML', label: 'Malayalam' },
    { value: 'AS', label: 'Assamese' },
    { value: 'MR', label: 'Marathi' },
    { value: 'SA', label: 'Sanskrit' },
    { value: 'MN', label: 'Mongolian' },
    { value: 'BO', label: 'Tibetan' },
    { value: 'CY', label: 'Welsh' },
    { value: 'KM', label: 'Central Khmer' },
    { value: 'LO', label: 'Lao' },
    { value: 'GL', label: 'Galician' },
    { value: 'KOK', label: 'Konkani' },
    { value: 'SYR', label: 'Syriac' },
    { value: 'SI', label: 'Sinhala' },
    { value: 'IU', label: 'Inuktitut' },
    { value: 'AM', label: 'Amharic' },
    { value: 'TZM', label: 'Central Atlas Tamazight' },
    { value: 'NE', label: 'Nepali' },
    { value: 'FY', label: 'Western Frisian' },
    { value: 'PS', label: 'Pushto' },
    { value: 'FIL', label: 'Filipino' },
    { value: 'DV', label: 'Dhivehi' },
    { value: 'HA', label: 'Hausa' },
    { value: 'YO', label: 'Yoruba' },
    { value: 'QUZ', label: 'Cusco Quechua' },
    { value: 'NSO', label: 'Pedi' },
    { value: 'BA', label: 'Bashkir' },
    { value: 'LB', label: 'Luxembourgish' },
    { value: 'KL', label: 'Kalaallisut' },
    { value: 'IG', label: 'Igbo' },
    { value: 'II', label: 'Sichuan Yi' },
    { value: 'ARN', label: 'Mapudungun' },
    { value: 'MOH', label: 'Mohawk' },
    { value: 'BR', label: 'Breton' },
    { value: 'UG', label: 'Uighur' },
    { value: 'MI', label: 'Maori' },
    { value: 'OC', label: 'Occitan' },
    { value: 'CO', label: 'Corsican' },
    { value: 'GSW', label: 'Swiss German' },
    { value: 'SAH', label: 'Yakut' },
    { value: 'QUT', label: 'Guatemala' },
    { value: 'RW', label: 'Kinyarwanda' },
    { value: 'WO', label: 'Wolof' },
    { value: 'PRS', label: 'Dari' },
    { value: 'GD', label: 'Scottish Gaelic' }
];


export const license = [
    { value: 'CC BY (Least Restrictive)', label: 'CC BY (Least Restrictive)' },
    { value: 'CC BY-SA', label: 'CC BY-SA' },
    { value: 'CC BY-ND', label: 'CC BY-ND' },
    { value: 'CC BY-NC', label: 'CC BY-NC' },
    { value: 'CC BY-NC-SA', label: 'CC BY-NC-SA' },
    { value: 'CC BY-NC-ND (Most Restrictive CC)', label: 'CC BY-NC-ND (Most Restrictive CC)' },
    { value: 'Ocean Archive (Most Restrictive)', label: 'Ocean Archive (Most Restrictive)' },
    
  ];

  export const cat = [
    { value: 'Other', label: 'Other' },
    { value: 'painting', label: 'painting' },
    { value: 'video', label: 'video' },
   
    
  ];